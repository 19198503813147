.floating-button {
    position: fixed;
    cursor: pointer;
    z-index: 9999;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  